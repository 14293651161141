.loginContainer {
  background-image: url(/assets/img/background3.jpg);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  height: 100%;
  width: 100%;
}
.loginContainer .overlay {
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.6);
}
.loginContainer .card-container {
  position: relative;
  z-index: 1;
  margin: 32px auto;
  max-width: 720px;
  height: 420px;
  perspective: 1000px;
  margin-left: 60%;
}
.loginContainer .card {
  box-shadow: #000 0 0 15px;
  width: 340px;
  height: 100%;
  transform-style: preserve-3d;
  transition: all 0.7s linear;
}
.loginContainer .card .title {
  display: flex;
  justify-content: center;
  align-items: center;
}
.loginContainer .card .title img {
  width: 70px;
  height: auto;
}
.loginContainer .card .form {
  padding: 15px 0;
  margin-top: 10px;
}
.loginContainer .card .form .field-wrapper:first-child {
  margin-bottom: 5px;
}
.loginContainer .card .form .field-wrapper:last-child {
  margin-top: 5px;
}
.loginContainer .card .form .field-wrapper input {
  padding-left: 35px;
  width: 280px;
  border: none;
  box-shadow: none;
  background: #e8f0fe;
}
.loginContainer .card .form .field-wrapper svg {
  position: absolute;
  height: 35px;
  width: 35px;
  padding: 8px;
}
.loginContainer .card .form .field-wrapper svg.emailSVG {
  left: 35px;
}
.loginContainer .card .actions {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 80px;
}
.card-side {
  padding: 30px;
  position: absolute;
  top: 0;
  overflow: hidden;
  width: 340px;
  height: 100%;
  backface-visibility: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.side-back {
  z-index: 2;
  padding: 32px;
  text-align: center;
  transform: rotateY(180deg);
}
/*- Flipping the card -*/
.card-container.flipped .card {
  transform: rotateY(180deg);
}
.card-container.flipped > .card > .side-front {
  opacity: 0;
  visibility: hidden;
  transition: opacity 1s ease-in, visibility 0.75s linear;
}
@media only screen and (max-width: 600px) {
  .loginContainer .card-container {
    position: relative;
    z-index: 1;
    width: 90%;
    height: 90%;
    perspective: 1000px;
    margin: auto;
 }
  .loginContainer .card-container .card {
    width: 100%;
 }
}

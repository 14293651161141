html {
	 scroll-behavior: smooth;
}
 @font-face {
	 font-family: 'VetancoFont';
	 src: url('/assets/fonts/SegoePro-Regular.ttf');
	/* IE9 Compat Modes */
}
 body {
	 margin: 0;
	 font-family: 'VetancoFont';
}
 *::-webkit-scrollbar-track {
	 background: #262c49;
	 border-radius: 20px;
}
 input {
	 color: #2d2d3c;
	 padding: 5px 10px;
	 background-color: #f5f5f5;
	 border: 1px solid rgba(0, 0, 0, 0.2);
	 border-radius: 5px;
	 box-shadow: 0 6px 10px 0 rgba(0, 0, 0, .14), 0 1px 18px 0 rgba(0, 0, 0, .12), 0 3px 5px -1px rgba(0, 0, 0, .2);
	 line-height: 24px;
	 font-size: 18px;
}
 .App, .mainContainer {
	 height: 100vh;
	 width: 100vw;
	 display: flex;
}
 .indexPage {
	 flex: 1;
	 background-color: #203c7c;
	 display: flex;
	 flex-direction: column;
}
 .indexPage .header {
	 display: flex;
	 justify-content: space-between;
	 align-items: center;
	 width: 100%;
	 height: 125px;
	 position: absolute;
	 padding: 0 50px;
	 margin-top: 45px;
	 z-index: 1;
}
 .indexPage .header .logo img {
	 height: 125px;
}
 .indexPage .tools-conatiner {
	 flex: 1;
	 display: flex;
	 justify-content: center;
	 align-items: center;
	 z-index: 2;
}
 .indexPage .tools-conatiner .tool {
	 background-color: white;
	 color: #2d2d3c;
	 border-radius: 0.5rem;
	 box-shadow: 0 4px 25px 0 rgba(0, 0, 0, 0.1);
	 -webkit-transition: all 0.3s ease-in-out;
	 margin: 40px;
	 height: 500px;
	 width: 350px;
	 display: flex;
	 flex-direction: column;
	 align-items: center;
	 justify-content: center;
	 cursor: pointer;
}
 .indexPage .tools-conatiner .tool.VIIP .icon {
	 height: 150px;
}
 .indexPage .tools-conatiner .tool:hover {
	 background-color: #1e2125;
}
 .indexPage .tools-conatiner .tool:hover .name {
	 color: white;
}
 .indexPage .tools-conatiner .tool .icon {
	 height: 200px;
	 width: 200px;
	 display: flex;
	 justify-content: center;
	 align-items: center;
}
 .indexPage .tools-conatiner .tool .icon img {
	 height: 100%;
	 width: 100%;
}
 .indexPage .tools-conatiner .tool .name {
	 font-size: 32px;
	 font-weight: 600;
	 margin-top: 20px;
}
 .indexPage .tools-conatiner .tool .message {
	 color: gray;
	 font-size: 18px;
	 text-align: center;
	 padding: 0 50px;
}
 .pageContainer {
	 background-color: #f5f5f5;
	 width: 100%;
	 max-height: 100vh;
	 overflow-y: auto;
}
 .swal2-popup.swal2-modal {
	 background-color: white;
}
 .swal2-popup.swal2-modal .swal2-title {
	 color: #2d2d3c !important;
	 font-size: 24px !important;
}
 .swal2-popup.swal2-modal .swal2-content {
	 color: #888ea8 !important;
}
 .loadingPage {
	 height: 100%;
	 width: 100%;
	 display: flex;
	 justify-content: center;
	 align-items: center;
}
 .card {
	 background-color: white;
	 color: #2d2d3c;
	 border-radius: 0.5rem;
	 box-shadow: 0 4px 25px 0 rgba(0, 0, 0, .1);
	 -webkit-transition: all 0.3s ease-in-out;
	 margin-bottom: 2.2rem !important;
	 width: 45%;
}
 .info {
	 position: relative;
}
 .info .tooltip {
	 display: none;
	 background: white;
	 box-shadow: 0 4px 25px 0 rgba(0, 0, 0, 0.3);
	 position: absolute;
	 opacity: 1;
	 width: 350px;
	 padding: 20px;
	 bottom: 30px;
	 left: 20px;
	 border-radius: 12px;
	 text-align: left;
}
 .info .tooltip .title {
	 color: blue;
	 padding: 0 !important;
}
 .info .tooltip hr {
	 width: 70px;
	 margin: 1rem 0 !important;
}
 .info:hover .tooltip {
	 display: block;
}
 .info:hover .icon {
	 color: blue;
}
 *::-webkit-scrollbar {
	 width: 7px;
}
 *::-webkit-scrollbar-track {
	 background: #aaa;
}
 *::-webkit-scrollbar-thumb {
	 background: #0f163a;
}
 *::-webkit-scrollbar-thumb:hover {
	 background: #262c49;
}
 
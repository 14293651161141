.section.observations .card.comments {
  width: 35%;
}
.section.observations .card.comments .chat {
  display: flex;
  justify-content: space-between;
  padding: 20px;
}
.section.observations .card.comments .chat .chatContainer {
  flex: 1 1;
  color: #3b3f5c;
  background-color: white;
  border-color: #f5f5f5;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.12), 0 2px 4px 0 rgba(0, 0, 0, 0.08);
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  margin: 15px;
  margin-top: 0;
  border-width: 1px;
  border-style: solid;
  border-radius: 0.5rem;
}
.section.observations .card.comments .noCommentsContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 20px;
}
.section.observations .card.comments .noCommentsContainer .message {
  font-size: 16px;
}
.section.observations .card.photos {
  width: 60%;
}
.section.observations .card.photos .photosContainer {
  padding: 20px;
  display: flex;
}
.section.observations .card.photos .photosContainer .photo {
  position: relative;
}
.section.observations .card.photos .photosContainer .photo:not(:last-child) {
  margin-right: 10px;
}
.section.observations .card.photos .photosContainer .photo img {
  height: 130px;
  opacity: 0.8;
}
.section.observations .card.photos .photosContainer .photo img:hover {
  cursor: pointer;
  opacity: 1;
}
.section.observations .card.photos .photosContainer .photo .icon {
  display: none;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}
.section.observations .card.photos .photosContainer .photo:hover .icon {
  display: flex;
  justify-content: center;
  align-items: center;
}
.section.observations .card.photos .noPhotos {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 20px;
}
.section.observations .card.photos .noPhotos .message {
  font-size: 16px;
}
.ant-carousel .slick-slide {
  height: 100%;
  overflow: hidden;
}
.ant-carousel .photo {
  display: flex;
  justify-content: center;
  align-items: center;
}
.ant-carousel .photo img {
  max-height: 80vh;
  height: 80vh;
  margin: auto;
  width: auto;
}

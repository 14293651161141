.ReactModalPortal .modalContainer.formData .loading {
  display: flex;
  justify-content: center;
  align-items: center;
}
.ReactModalPortal .modalContainer.formData .ready {
  display: flex;
}
.ReactModalPortal .modalContainer.formData .ready .column {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  padding-top: 20px;
  flex: 1;
  min-width: 400px;
}
.ReactModalPortal .modalContainer.formData .ready .column.general .title {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 28px;
  line-height: 28px;
  font-weight: 600;
  padding: 20px;
}
.ReactModalPortal .modalContainer.formData .ready .column.general .title .icon {
  margin-right: 15px;
}
.ReactModalPortal .modalContainer.formData .ready .column.general .title .switch {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 20px;
}
.ReactModalPortal .modalContainer.formData .ready .column.general .data {
  width: 100%;
  padding: 20px;
  flex: 1;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
}
.ReactModalPortal .modalContainer.formData .ready .column.general .data .card {
  width: 100%;
  padding: 20px;
}
.ReactModalPortal .modalContainer.formData .ready .column.general .data .card .head {
  font-size: 22px;
  margin-bottom: 10px;
}
.ReactModalPortal .modalContainer.formData .ready .column.general .data .card .body .item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 40px;
  padding: 10px 0;
}
.ReactModalPortal .modalContainer.formData .ready .column.general .data .card .body .item .tag {
  color: gray;
  max-width: 140px;
  line-height: 15px;
}
.ReactModalPortal .modalContainer.formData .ready .column.general .data .card .body .item .value {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.ReactModalPortal .modalContainer.formData .ready .column.general .data .card .body .item .value input {
  box-shadow: none;
}
.ReactModalPortal .modalContainer.formData .ready .column.general .data .card .body .item .value.edad input {
  padding-right: 35px;
}
.ReactModalPortal .modalContainer.formData .ready .column.general .data .card .body .item .value.edad .unit {
  position: absolute;
  right: 25px;
  line-height: 32px;
  color: gray;
}
.ReactModalPortal .modalContainer.formData .ready .column.chicken {
  padding: 20px;
}
.ReactModalPortal .modalContainer.formData .ready .column.chicken .card {
  width: 100%;
  padding: 20px;
  margin-bottom: 0 !important;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
.ReactModalPortal .modalContainer.formData .ready .column.chicken .card .title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.ReactModalPortal .modalContainer.formData .ready .column.chicken .card .title .index {
  font-size: 26px;
  font-weight: 60px;
  text-align: center;
}
.ReactModalPortal .modalContainer.formData .ready .column.chicken .card .title .prev, .ReactModalPortal .modalContainer.formData .ready .column.chicken .card .title .next {
  cursor: pointer;
}
.ReactModalPortal .modalContainer.formData .ready .column.chicken .card .title .prev.hidden, .ReactModalPortal .modalContainer.formData .ready .column.chicken .card .title .next.hidden {
  visibility: hidden;
}
.ReactModalPortal .modalContainer.formData .ready .column.chicken .card .title .prev svg, .ReactModalPortal .modalContainer.formData .ready .column.chicken .card .title .next svg {
  height: 20px;
  width: 20px;
}
.ReactModalPortal .modalContainer.formData .ready .column.chicken .card .block {
  width: 100%;
}
.ReactModalPortal .modalContainer.formData .ready .column.chicken .card .block .head {
  font-size: 18px;
  margin: 10px 0;
}
.ReactModalPortal .modalContainer.formData .ready .column.chicken .card .block .body .item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 32px;
}
.ReactModalPortal .modalContainer.formData .ready .column.chicken .card .block .body .item .tag {
  color: gray;
}
.ReactModalPortal .modalContainer.formData .ready .column.chicken .card .block .body .item .value {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 150px;
}
.ReactModalPortal .modalContainer.formData .ready .column.chicken .card .block .body .item .ant-select-multiple .ant-select-selector {
  background-color: white;
  max-height: 35px;
  padding: 0px !important;
  flex-wrap: nowrap;
}

.formsPage {
  padding: 40px;
  display: flex;
  flex-direction: column;
  height: 100%;
}
.formsPage .header {
  display: flex;
  color: #2d2d3c;
  font-size: 25px;
  line-height: 25px;
  margin-bottom: 20px;
  justify-content: flex-start;
  align-items: center;
}
.formsPage .header .separator {
  line-height: 25px;
  padding: 0 10px;
}
.formsPage .header .icon {
  height: 25px;
  width: 25px;
}
.formsPage .header .icon img {
  height: 100%;
  width: 100%;
}
.formsPage .header .btn-group {
  margin-left: 20px;
}
.formsPage .formulariosList {
  display: flex;
  flex: 1;
}
.formsPage .formulariosList .formularioListItem {
  display: flex;
  align-items: center;
  background-color: white;
  padding: 10px;
  border-radius: 0.5em;
  color: #2d2d3c;
  margin-bottom: 5px;
  text-align: center;
}
.formsPage .formulariosList .formularioListItem .datoInfo {
  width: 100px;
  padding: 0 10px;
  overflow: hidden;
}
.formsPage .formulariosList .formularioListItem .datoPromedio {
  width: 100px;
  padding: 0 10px;
  overflow: hidden;
}
.formsPage .formulariosList .formularioListItem .datoResultado {
  width: 100px;
  padding: 0 10px;
  overflow: hidden;
}
.formsPage .formulariosList .formularioListItem .datoResultado .container {
  width: 50px;
  border-radius: 7px;
  padding: 0;
}
.formsPage .formulariosList .formularioListItem.header {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 10px;
  background-color: #c8c8eb;
  color: #3b3f5c;
}
.formsPage .formulariosList .formularioListItem.header .checkName .name {
  margin-left: 39px;
}
.formsPage .formulariosList .formularioListItem.header .actions {
  margin-left: 39px;
  margin-right: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  visibility: hidden;
}
.formsPage .formulariosList .formularioListItem.header .actions img {
  height: 20px;
  width: 20px;
}
.formsPage .noForms {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.formsPage .noForms .logo {
  height: 180px;
  margin-top: 80px;
  font-size: 120px;
}
.formsPage .noForms .logo svg {
  height: 100%;
  width: auto;
}
.formsPage .noForms .title {
  font-size: 32px;
  padding: 40px;
  padding-bottom: 0;
}
.formsPage .noForms .message {
  text-align: center;
  padding: 20px;
  color: #c2c6dc;
  font-size: 18px;
}
.formsPage .noForms .message p {
  margin: 0;
}

.farmsPage {
  padding: 40px;
  display: flex;
  flex-direction: column;
  height: 100%;
}
.farmsPage .header {
  display: flex;
  color: #2d2d3c;
  font-size: 25px;
  line-height: 25px;
  margin-bottom: 20px;
  justify-content: flex-start;
}
.farmsPage .header .separator {
  line-height: 25px;
  padding: 0 10px;
}
.farmsPage .header .icon {
  height: 25px;
  width: 25px;
}
.farmsPage .header .icon img {
  height: 100%;
  width: 100%;
}
.farmsPage .card.data {
  height: auto;
  width: auto;
  padding: 20px;
}
.farmsPage .card.data .title {
  font-size: 22px;
  font-weight: 600;
  margin-bottom: 20px;
}
.farmsPage .card.data .dataValues .dataItem {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  line-height: 35px;
}
.farmsPage .card.data .dataValues .dataItem .tag {
  font-weight: 600;
  color: #1e2125;
}
.farmsPage .card.data .dataValues .dataItem .value {
  font-size: 18px;
}
.farmsPage .card.map {
  width: auto;
  flex: 1;
}
.farmsPage .card.map .mapContainer {
  height: 100%;
  width: 100%;
}
.farmsPage .card.map .mapContainer .leaflet-container {
  height: 100%;
  width: 100%;
}
.farmsPage .card.map .mapContainer .action {
  position: absolute;
  z-index: 1000;
  top: 10px;
  right: 10px;
}
.farmsPage .card.map .noPosition {
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
}
.farmsPage .card.map .noPosition .container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: #fff069;
  width: 90%;
  height: 90%;
  border: solid;
}
.farmsPage .card.map .noPosition .container .image img {
  height: 100px;
  width: 100px;
}
.farmsPage .card.map .noPosition .container .tag {
  margin-top: 40px;
  font-size: 18px;
  text-align: center;
}
.farmsPage .card.map .noPosition .container .action {
  margin-top: 20px;
}
.farmsPage .farmList {
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: white;
  margin-bottom: 2rem !important;
  border-radius: 0.5rem;
  box-shadow: 0 4px 25px 0 rgba(0, 0, 0, 0.1);
  -webkit-transition: all 0.3s ease-in-out;
  padding-top: 20px;
}
.farmsPage .farmList .filter {
  margin-bottom: 20px;
  padding: 0 20px;
}
.farmsPage .farmList .filter .search input {
  width: 100%;
  background-image: url(/assets/img/icons/lupa.svg);
  background-size: 15px;
  background-position: 98% 10px;
  background-repeat: no-repeat;
}
.farmsPage .farmList .filter .search input:focus {
  background-image: none;
  outline: none;
}
.farmsPage .farmList .list {
  overflow: auto;
  padding: 0 20px;
}
.farmsPage .farmList .list .farmListItem {
  border-radius: 0.5rem;
  box-shadow: 0 4px 25px 0 rgba(0, 0, 0, 0.1);
  -webkit-transition: all 0.3s ease-in-out;
  margin-bottom: 8px !important;
  padding: 10px 20px;
  text-align: center;
  cursor: pointer;
}
.farmsPage .farmList .list .farmListItem.selected {
  background: #fbf6cc;
  border: solid 1px #ffeb3b;
}
.farmsPage .farmList .list .farmListItem .name {
  font-size: 18px;
}
.farmsPage .farmList .list .farmListItem .renspa {
  font-size: 12px;
  color: #bdbdbd;
}
.farmsPage .container {
  height: calc(100% - 45px);
  max-width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.farmsPage .container .column {
  flex: 1;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.farmsPage .container .column:first-child {
  margin-right: 10px;
}
.farmsPage .container .column:last-child {
  margin-left: 10px;
}
.farmsPage .noFarms {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.farmsPage .noFarms .logo {
  height: 180px;
  margin-top: 80px;
}
.farmsPage .noFarms .logo img {
  height: 100%;
  width: auto;
}
.farmsPage .noFarms .title {
  font-size: 32px;
  padding: 40px;
  padding-bottom: 0;
}
.farmsPage .noFarms .message {
  text-align: center;
  padding: 20px;
  color: #c2c6dc;
  font-size: 18px;
}
.farmsPage .noFarms .message p {
  margin: 0;
}
.farmsPage.map {
  padding: 0;
}
.farmsPage.map .mapContainer {
  height: 100%;
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.farmsPage.map .mapContainer .card {
  position: absolute;
  top: 10px;
  z-index: 1000;
  width: auto;
  padding: 20px;
  text-align: center;
}
.farmsPage.map .mapContainer .card .title {
  font-size: 18px;
  margin-bottom: 5px;
}
.farmsPage.map .mapContainer .card .message {
  font-size: 12px;
  color: gray;
}
.farmsPage.map .mapContainer .marker {
  position: absolute;
  z-index: 1000;
}
.farmsPage.map .mapContainer .marker svg, .farmsPage.map .mapContainer .marker img {
  height: 40px;
  width: 40px;
}
.farmsPage.map .mapContainer .footer {
  position: absolute;
  bottom: 10px;
  z-index: 1000;
  width: auto;
  padding: 20px;
  background-color: white;
  color: #2d2d3c;
  border-radius: 0.5rem;
  box-shadow: 0 4px 25px 0 rgba(0, 0, 0, 0.1);
  -webkit-transition: all 0.3s ease-in-out;
}
.farmsPage.map .mapContainer .footer button:first-child {
  margin-right: 20px;
}
.farmsPage.map .mapContainer .leaflet-container {
  height: 100%;
  width: 100%;
}

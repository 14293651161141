.section.history .card .graph {
  height: 420px;
}
.section.history .card .head {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  padding: 1.5rem 1.5rem 0;
}
.section.history .card .head .title {
  padding: 0;
}
.section.history .card .head .references {
  border: solid 1px #c2c6dc;
  border-radius: 12px;
  padding: 20px 45px 10px 15px;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.4);
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: white;
  z-index: 1;
}
.section.history .card .head .references .info {
  font-size: 18px;
  position: absolute;
  top: 5px;
  right: 5px;
}
.section.history .card .head .references .info .tooltip {
  min-height: 265px;
  min-width: 350px;
  right: 20px;
  left: auto;
  top: 20px;
  bottom: 0;
}
.section.history .card .head .references .reference {
  display: flex;
  align-items: center;
  padding: 5px 0;
}
.section.history .card .head .references .reference .color {
  height: 2px;
  width: 20px;
  margin-right: 15px;
}
.custom-tooltip {
  padding: 20px;
}
.custom-tooltip .titulo {
  display: flex;
  justify-content: center;
  align-items: baseline;
  padding-bottom: 5px;
  border-bottom: solid 1px rgba(194, 198, 220, 0.6);
  text-align: center;
}
.custom-tooltip .titulo .tag {
  color: #2d2d3c;
  font-size: 14px;
}
.custom-tooltip .titulo .percentage {
  color: black;
  font-size: 20px;
  text-align: center;
}
.custom-tooltip .body {
  padding-top: 10px;
}
.custom-tooltip .body > div {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  line-height: 15px;
  padding: 5px 0;
}
.custom-tooltip .body > div .tag {
  font-size: 12px;
}
.custom-tooltip .body > div .value {
  padding-left: 20px;
  font-size: 14px;
}

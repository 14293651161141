.profilePage {
  padding: 40px;
}
.profilePage .header {
  display: flex;
  color: #2d2d3c;
  font-size: 25px;
  line-height: 25px;
  margin-bottom: 20px;
  justify-content: flex-start;
}
.profilePage .header .separator {
  line-height: 25px;
  padding: 0 10px;
}
.profilePage .header .icon {
  height: 25px;
  width: 25px;
}
.profilePage .header .icon img {
  height: 100%;
  width: 100%;
}
.profilePage .card {
  border-radius: 0.5rem;
  box-shadow: 0 4px 25px 0 rgba(0, 0, 0, .1);
  -webkit-transition: all 0.3s ease-in-out;
  margin-bottom: 2.2rem !important;
  width: 45%;
}
.profilePage .card.full {
  width: 100%;
}
.profilePage .card .title {
  padding: 1.5rem 1.5rem 0;
  font-size: 18.48px;
}
.profilePage .card .data {
  display: flex;
}
.profilePage .card .data .icon {
  padding: 30px;
}
.profilePage .card .data .icon img {
  height: 90px;
  width: 90px;
}
.profilePage .card .data .dataValues {
  display: flex;
  flex: 3;
  padding: 30px 20px;
}
.profilePage .card .data .dataValues .column {
  display: flex;
  flex-direction: column;
  flex: 1;
}
.profilePage .card .data .dataValues .column .dataItem {
  display: flex;
}
.profilePage .card .data .dataValues .column .dataItem .tag {
  min-width: 140px;
  padding-bottom: 0.8rem;
  font-weight: 600 !important;
}
.profilePage .card .data .dataValues .column .dataItem .value {
  padding-bottom: 0.8rem;
}
.profilePage .card .actions {
  display: flex;
  justify-content: flex-start;
  padding: 30px;
  padding-top: 0;
}
.profilePage .section {
  display: flex;
  justify-content: space-between;
}
.profilePage .section .card.language {
  width: 20%;
  min-width: 200px;
}
.profilePage .section .card.password {
  width: 75%;
}
.profilePage .section .card.password .content {
  align-items: flex-start;
}
.profilePage .section .card .content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  height: 145px;
}
.profilePage .section .card .content .column {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.profilePage .section .card .content .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  background-color: #f5f5f5;
  color: #2d2d3c;
}
.profilePage .section .card .content .ant-select-arrow {
  color: #2d2d3c;
}
.profilePage .section .card .content .line.newPassword {
  margin-bottom: 20px;
}
.profilePage .section .card .footer {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  padding-top: 0;
}

.section.clostridiosis .card.clostridiosisSpeedometer {
  width: 48%;
}
.section.clostridiosis .card.clostridiosisSpeedometer .head {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  padding: 1.5rem 1.5rem 0;
}
.section.clostridiosis .card.clostridiosisSpeedometer .head .title {
  padding: 0;
}
.section.clostridiosis .card.clostridiosisSpeedometer .head .info {
  font-size: 18px;
}
.section.clostridiosis .card.clostridiosisSpeedometer .graph {
  height: auto;
  width: auto;
  max-width: 440px;
  overflow: hidden;
  padding: 20px 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.section.clostridiosis .card.composition {
  width: 48%;
}
.section.clostridiosis .card.composition .head {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  padding: 1.5rem 1.5rem 0;
}
.section.clostridiosis .card.composition .head .title {
  padding: 0;
}
.section.clostridiosis .card.composition .head .info {
  font-size: 18px;
}
.section.clostridiosis .card.composition .head .info .tooltip {
  right: 20px;
  left: auto;
}
.section.clostridiosis .card.composition .rowContainer {
  min-width: 440px;
  padding: 20px;
}
.section.clostridiosis .card.composition .rowContainer .graph {
  padding: 0;
  height: 200px;
  width: 200px;
}
.section.clostridiosis .card.composition .rowContainer .references {
  min-width: 225px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding-left: 20px;
}
.section.clostridiosis .card.composition .rowContainer .references .reference {
  display: flex;
  align-items: center;
  padding: 15px 0;
}
.section.clostridiosis .card.composition .rowContainer .references .reference .color {
  height: 20px;
  width: 20px;
  border-radius: 50%;
  margin-right: 15px;
}
.section.clostridiosis .card.composition .rowContainer .references .reference.intestinal .color {
  background-color: #0088fe;
}
.section.clostridiosis .card.composition .rowContainer .references .reference.coccidiosis .color {
  background-color: #00c49f;
}

.dashboardsPage .card.intestinalQualityPercentage {
  align-items: center;
  width: 35%;
  min-width: 300px;
  margin-right: 20px;
}
.dashboardsPage .card.intestinalQualityPercentage .head {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  padding: 1.5rem 1.5rem 0;
  text-align: center;
}
.dashboardsPage .card.intestinalQualityPercentage .head .title {
  padding: 0;
}
.dashboardsPage .card.intestinalQualityPercentage .head .info {
  font-size: 18px;
}
.dashboardsPage .card.intestinalQualityPercentage .graph {
  width: 290px;
  position: relative;
  padding: 0 20px;
  margin-top: 40px;
}
.dashboardsPage .card.intestinalQualityPercentage .graph .percentage {
  position: absolute;
  color: #28283c;
  width: 100%;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: baseline;
  padding-top: 60px;
}
.dashboardsPage .card.intestinalQualityPercentage .graph .percentage .value {
  font-size: 88px;
}
.dashboardsPage .card.intestinalQualityPercentage .graph .percentage .unit {
  font-size: 30px;
}
.dashboardsPage .card.intestinalQualityPercentage .subtitle {
  padding-bottom: 1.5rem;
  color: #2d2d3c;
  font-size: 18.48px;
}
.dashboardsPage .card.intestinalQualitySummery {
  width: 60%;
  min-width: 400px;
}
.dashboardsPage .card.intestinalQualitySummery .summery .headerImage {
  position: absolute;
  top: -1px;
  left: -1px;
}
.dashboardsPage .card.intestinalQualitySummery .summery .headerImage img {
  width: 350px;
  border-top-left-radius: 0.5rem;
}
.dashboardsPage .card.intestinalQualitySummery .summery .result .title {
  margin-top: 80px;
  font-size: 28px;
  text-align: center;
  padding: 0;
}
.dashboardsPage .card.intestinalQualitySummery .summery .result .message {
  padding-top: 8px;
  font-size: 16px;
  max-width: 400px;
  margin: auto;
  text-align: center;
  color: #2d2d3c;
}
.dashboardsPage .card.intestinalQualitySummery .summery .result .indexesIncurrence {
  display: flex;
  justify-content: space-evenly;
}
.dashboardsPage .card.intestinalQualitySummery .summery .result .indexesIncurrence .index {
  height: 130px;
  width: 110px;
  text-align: center;
  cursor: pointer;
}
.dashboardsPage .card.intestinalQualitySummery .summery .result .indexesIncurrence .index div {
  text-decoration: none;
  color: #2d2d3c;
}
.dashboardsPage .card.intestinalQualitySummery .summery .result .indexesIncurrence .index div .percentage .value {
  font-size: 50px;
}
.dashboardsPage .card.intestinalQualitySummery .summery .result .indexesIncurrence .index div .tag {
  color: #2d2d3c;
}

.productsPage {
  padding: 40px;
  display: flex;
  flex-direction: column;
  height: 100%;
}
.productsPage .header {
  display: flex;
  color: #2d2d3c;
  font-size: 25px;
  line-height: 25px;
  margin-bottom: 20px;
  justify-content: flex-start;
}
.productsPage .header .separator {
  line-height: 25px;
  padding: 0 10px;
}
.productsPage .header .icon {
  height: 25px;
  width: 25px;
}
.productsPage .header .icon img {
  height: 100%;
  width: 100%;
}
.productsPage .columns {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.productsPage .columns > div {
  flex: 1;
  margin: 0 10px;
  margin-top: 20px;
}
.productsPage .card.data {
  height: auto;
  width: auto;
  padding: 20px;
}
.productsPage .card.data .title {
  font-size: 22px;
  font-weight: 600;
  margin-bottom: 20px;
}
.productsPage .card.data .dataValues .dataItem {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  line-height: 35px;
}
.productsPage .card.data .dataValues .dataItem .tag {
  font-weight: 600;
  color: #1e2125;
}
.productsPage .card.data .dataValues .dataItem .value {
  font-size: 18px;
}
.productsPage .productList {
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: white;
  margin-bottom: 2rem !important;
  border-radius: 0.5rem;
  box-shadow: 0 4px 25px 0 rgba(0, 0, 0, 0.1);
  -webkit-transition: all 0.3s ease-in-out;
  padding: 20px;
  overflow: hidden;
}
.productsPage .productList .title {
  font-size: 18px;
}
.productsPage .productList hr {
  width: 40px;
  margin: 10px 0;
  margin-bottom: 20px;
}
.productsPage .productList .filter {
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.productsPage .productList .filter .search input {
  width: 100%;
  background-image: url(/assets/img/icons/lupa.svg);
  background-size: 15px;
  background-position: 98% 10px;
  background-repeat: no-repeat;
}
.productsPage .productList .filter .search input:focus {
  background-image: none;
  outline: none;
}
.productsPage .productList .list {
  flex: 1;
  overflow: auto;
}
.productsPage .productList .list .productListItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 0.5rem;
  box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.1);
  -webkit-transition: all 0.3s ease-in-out;
  margin: 8px;
  padding: 10px 20px;
  text-align: center;
  cursor: pointer;
}
.productsPage .productList .list .productListItem.selected {
  background: #fbf6cc;
  border: solid 1px #ffeb3b;
}
.productsPage .productList .list .productListItem .name {
  font-size: 18px;
}
.ReactModalPortal .modalContainer.newProduct {
  display: flex;
}
.ReactModalPortal .modalContainer.newProduct .column {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  padding-top: 20px;
  width: 120px;
  font-size: 120px;
  line-height: 120px;
}
.ReactModalPortal .modalContainer.newProduct .data {
  width: 350px;
  padding: 20px;
}
.ReactModalPortal .modalContainer.newProduct .data .form {
  width: 100%;
}
.ReactModalPortal .modalContainer.newProduct .data .form .line {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.ReactModalPortal .modalContainer.newProduct .data .form .line.name {
  margin: 45px 0;
}
.ReactModalPortal .modalContainer.newProduct .data .form .line.name .form-input input {
  width: 310px;
}
.ReactModalPortal .modalContainer.newProduct .data .footer {
  display: flex;
  justify-content: flex-end;
}

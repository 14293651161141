.page.maintenance {
    height: 100vh;
    width: 100vw;
    background: linear-gradient(90deg, #262c49, #181e3b);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.page.maintenance .icon {
    height: 180px;
    width: 180px;
    background: white;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 96px;
    color: red;
    margin-bottom: 30px;
}
.page.maintenance .title {
    color: white;
    font-size: 40px;
    margin-bottom: 10px;
}
.page.maintenance .message {
    color: whitesmoke;
    font-size: 19px;
}

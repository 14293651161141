.userPage {
  padding: 40px;
  display: flex;
  flex-direction: column;
  height: 100%;
}
.userPage .header {
  display: flex;
  color: #2d2d3c;
  font-size: 25px;
  line-height: 25px;
  margin-bottom: 20px;
  justify-content: flex-start;
}
.userPage .header .separator {
  line-height: 25px;
  padding: 0 10px;
}
.userPage .header .icon {
  height: 25px;
  width: 25px;
}
.userPage .header .icon img {
  height: 100%;
  width: 100%;
}
.userPage .filter {
  background-color: white;
  margin-bottom: 2rem !important;
  border-radius: 0.5rem;
  box-shadow: 0 4px 25px 0 rgba(0, 0, 0, .1);
  -webkit-transition: all 0.3s ease-in-out;
}
.userPage .filter .title {
  padding: 1.5rem 1.5rem 0;
  font-size: 18.48px;
}
.userPage .filter .actions {
  padding: 20px;
  display: flex;
  justify-content: space-between;
}
.userPage .filter .actions .search input {
  width: 350px;
  background-image: url(/assets/img/icons/lupa.svg);
  background-size: 15px;
  background-position: 325px 10px;
  background-repeat: no-repeat;
}
.userPage .filter .actions .search input:focus {
  background-image: none;
  outline: none;
}
.userPage .filter .actions .options {
  display: flex;
}
.userPage .filter .actions .options > div {
  background-color: #f5f5f5;
  border-radius: 5px;
  padding: 5px;
  cursor: pointer;
  box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12), 0 3px 5px -1px rgba(0, 0, 0, 0.2);
  width: 26px;
  height: 26px;
}
.userPage .filter .actions .options > div.newUser {
  margin-right: 20px;
  height: 36px;
  width: 36px;
}
.userPage .filter .actions .options > div img {
  height: 26px;
  width: 26px;
}
.userPage .userList {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
}
.userPage .userList .userListItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  padding: 10px;
  border-radius: 0.5em;
  color: #2d2d3c;
  margin-bottom: 5px;
}
.userPage .userList .userListItem .name {
  width: 210px;
  text-align: center;
  margin-left: 39px;
}
.userPage .userList .userListItem .email {
  width: 210px;
  text-align: center;
}
.userPage .userList .userListItem .role {
  margin-left: 39px;
}
.userPage .userList .userListItem .actions {
  margin-left: 39px;
  margin-right: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.userPage .userList .userListItem .actions img {
  height: 20px;
  width: 20px;
}
.userPage .userList .userListItem.header {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 10px;
  background-color: #c8c8eb;
  color: #3b3f5c;
}
.userPage .userList .userListItem.header .checkName .name {
  margin-left: 39px;
}
.userPage .userList .userListItem.header .actions {
  margin-left: 39px;
  margin-right: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  visibility: hidden;
}
.userPage .userList .userListItem.header .actions img {
  height: 20px;
  width: 20px;
}
.userPage .userList .pagination {
  margin-top: 20px;
  margin-bottom: 0;
  justify-content: flex-end;
}
.userPage .userList .pagination li {
  height: 35px;
  width: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #212529;
  cursor: pointer;
}
.userPage .userList .pagination li.active {
  background-color: #c8c8eb;
}
.userPage .userList .pagination li.active a {
  color: #2d2d3c;
}
.userPage .userList .pagination li:first-child {
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
}
.userPage .userList .pagination li:last-child {
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
}
.userPage .userList .pagination li a {
  color: #bfc9d4;
}
.ReactModalPortal .modalContainer.newUser {
  display: flex;
}
.ReactModalPortal .modalContainer.newUser .column {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  padding-top: 20px;
  width: 120px;
}
.ReactModalPortal .modalContainer.newUser .column img {
  height: 120px;
  width: 120px;
}
.ReactModalPortal .modalContainer.newUser .data {
  width: 350px;
  padding: 20px;
}
.ReactModalPortal .modalContainer.newUser .data .form {
  width: 100%;
}
.ReactModalPortal .modalContainer.newUser .data .form .line {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.ReactModalPortal .modalContainer.newUser .data .form .line.name {
  margin-bottom: 20px;
}
.ReactModalPortal .modalContainer.newUser .data .form .line.name .form-input input {
  width: 150px;
}
.ReactModalPortal .modalContainer.newUser .data .form .line.email {
  margin-bottom: 20px;
}
.ReactModalPortal .modalContainer.newUser .data .form .line.email .form-input input {
  width: 310px;
}
.ReactModalPortal .modalContainer.newUser .data .form .line.extra {
  margin-bottom: 30px;
}
.ReactModalPortal .modalContainer.newUser .data .form .line.extra .form-select .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  height: 36px;
  color: #2d2d3c;
  padding: 5px 10px;
  background-color: #f5f5f5;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12), 0 3px 5px -1px rgba(0, 0, 0, 0.2);
  line-height: 24px;
  font-size: 16px;
}
.ReactModalPortal .modalContainer.newUser .data .form .line.extra .form-select .ant-select-arrow {
  color: #2d2d3c;
}
.ReactModalPortal .modalContainer.newUser .data .footer {
  display: flex;
  justify-content: flex-end;
}

.alarmsPage {
  padding: 40px;
}
.alarmsPage .header {
  display: flex;
  color: #2d2d3c;
  font-size: 25px;
  line-height: 25px;
  margin-bottom: 20px;
  justify-content: space-between;
}
.alarmsPage .header .left {
  display: flex;
  color: #2d2d3c;
  font-size: 25px;
  line-height: 25px;
  margin-bottom: 20px;
  justify-content: flex-start;
}
.alarmsPage .header .left .separator {
  line-height: 25px;
  padding: 0 10px;
}
.alarmsPage .header .left .icon {
  height: 25px;
  width: 25px;
}
.alarmsPage .header .left .icon img {
  height: 100%;
  width: 100%;
}
.alarmsPage .sections .title {
  font-size: 32px;
  padding-bottom: 0;
  text-align: center;
}
.alarmsPage .sections .message {
  text-align: center;
  padding: 20px;
  color: #c2c6dc;
  font-size: 18px;
  margin-bottom: 40px;
}
.alarmsPage .sections .message p {
  margin: 0;
}
.alarmsPage .sections .alarms {
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
}
.alarmsPage .sections .alarms .colContainer {
  width: 48%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: column;
}
.alarmsPage .sections .alarms .card {
  width: 100%;
}
.alarmsPage .sections .alarms .card .header {
  margin: 0;
}
.alarmsPage .sections .alarms .card .header .title {
  padding: 30px;
  padding-bottom: 0;
  text-align: left;
  line-height: 32px;
}
.alarmsPage .sections .alarms .card .header .activation {
  margin: 10px;
}
.alarmsPage .sections .alarms .card .header .activation .ant-switch-checked {
  background-color: #37a745;
}
.alarmsPage .sections .alarms .card .body .message {
  margin: 0;
}
.alarmsPage .sections .alarms .card .rule {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-bottom: 20px;
}
.alarmsPage .sections .alarms .card .rule > div {
  height: 80px;
  width: 80px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  background-color: white;
  color: #2d2d3c;
  text-align: center;
  border-radius: 0.5rem;
  box-shadow: 0 4px 25px 0 rgba(0, 0, 0, 0.1);
}
.alarmsPage .sections .alarms .card .rule .operator button {
  border: none;
  height: 100%;
  width: 100%;
  padding: 0;
  text-align: center;
  background: transparent;
  box-shadow: none;
  white-space: break-spaces;
}
.alarmsPage .sections .alarms .card .rule .value {
 /* Chrome, Safari, Edge, Opera */
 /* Firefox */
}
.alarmsPage .sections .alarms .card .rule .value input {
  height: 100%;
  width: 100%;
  border: none;
  text-align: center;
  background: transparent;
  box-shadow: none;
}
.alarmsPage .sections .alarms .card .rule .value input:focus {
  border: none;
}
.alarmsPage .sections .alarms .card .rule .value input::-webkit-outer-spin-button, .alarmsPage .sections .alarms .card .rule .value input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.alarmsPage .sections .alarms .card .rule .value input[type=number] {
  -moz-appearance: textfield;
}

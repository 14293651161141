.zonesPage {
  padding: 40px;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}
.zonesPage.map {
  padding: 0;
}
.zonesPage .header {
  display: flex;
  color: #2d2d3c;
  font-size: 25px;
  line-height: 25px;
  margin-bottom: 20px;
  justify-content: flex-start;
}
.zonesPage .header .separator {
  line-height: 25px;
  padding: 0 10px;
}
.zonesPage .header .icon {
  height: 25px;
  width: 25px;
}
.zonesPage .header .icon img {
  height: 100%;
  width: 100%;
}
.zonesPage .filter {
  background-color: white;
  margin-bottom: 2rem !important;
  border-radius: 0.5rem;
  box-shadow: 0 4px 25px 0 rgba(0, 0, 0, .1);
  -webkit-transition: all 0.3s ease-in-out;
}
.zonesPage .filter .title {
  padding: 1.5rem 1.5rem 0;
  font-size: 18.48px;
}
.zonesPage .filter .actions {
  padding: 20px;
  display: flex;
  justify-content: space-between;
}
.zonesPage .filter .actions .search input {
  width: 350px;
  background-image: url(/assets/img/icons/lupa.svg);
  background-size: 15px;
  background-position: 325px 10px;
  background-repeat: no-repeat;
}
.zonesPage .filter .actions .search input:focus {
  background-image: none;
}
.zonesPage .filter .actions .options {
  display: flex;
}
.zonesPage .filter .actions .options > div {
  background-color: #f5f5f5;
  border-radius: 5px;
  padding: 5px;
  cursor: pointer;
  box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12), 0 3px 5px -1px rgba(0, 0, 0, 0.2);
  width: 26px;
  height: 26px;
}
.zonesPage .filter .actions .options > div.newZone {
  margin-right: 20px;
  height: 36px;
  width: 36px;
}
.zonesPage .filter .actions .options > div img {
  height: 26px;
  width: 26px;
}
.zonesPage .zonesList {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
}
.zonesPage .zonesList .zonesListItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  padding: 10px;
  border-radius: 0.5em;
  color: #2d2d3c;
  margin-bottom: 5px;
}
.zonesPage .zonesList .zonesListItem .name {
  display: flex;
  width: 200px;
  margin-left: 39px;
}
.zonesPage .zonesList .zonesListItem .email {
  width: 210px;
  text-align: center;
}
.zonesPage .zonesList .zonesListItem .language {
  margin-left: 39px;
}
.zonesPage .zonesList .zonesListItem .actions {
  margin-left: 39px;
  margin-right: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.zonesPage .zonesList .zonesListItem .actions .action {
  padding: 0 5px;
  cursor: pointer;
}
.zonesPage .zonesList .zonesListItem .actions .action.info:hover {
  color: #2590ff;
}
.zonesPage .zonesList .zonesListItem .actions .action.edit:hover {
  color: #28c76f;
}
.zonesPage .zonesList .zonesListItem .actions .action.remove:hover {
  color: #ea5455;
}
.zonesPage .zonesList .zonesListItem.header {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 10px;
  background-color: #c8c8eb;
  color: #3b3f5c;
}
.zonesPage .zonesList .zonesListItem.header .checkName .name {
  margin-left: 39px;
}
.zonesPage .zonesList .zonesListItem.header .email, .zonesPage .zonesList .zonesListItem.header .language {
  margin-left: 39px;
}
.zonesPage .zonesList .zonesListItem.header .actions {
  margin-left: 39px;
  margin-right: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  visibility: hidden;
}
.zonesPage .zonesList .zonesListItem.header .actions img {
  height: 20px;
  width: 20px;
}
.zonesPage .zonesList .pagination {
  margin-top: 20px;
  margin-bottom: 0;
  justify-content: flex-end;
}
.zonesPage .zonesList .pagination li {
  height: 35px;
  width: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #212529;
  cursor: pointer;
}
.zonesPage .zonesList .pagination li.active {
  background-color: #c8c8eb;
}
.zonesPage .zonesList .pagination li.active a {
  color: #2d2d3c;
}
.zonesPage .zonesList .pagination li:first-child {
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
}
.zonesPage .zonesList .pagination li:last-child {
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
}
.zonesPage .zonesList .pagination li a {
  color: #bfc9d4;
}
.zonesPage .noZones {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.zonesPage .noZones .logo {
  height: 180px;
  margin-top: 80px;
}
.zonesPage .noZones .logo img {
  height: 100%;
  width: auto;
}
.zonesPage .noZones .title {
  font-size: 32px;
  padding: 40px;
  padding-bottom: 0;
}
.zonesPage .noZones .message {
  text-align: center;
  padding: 20px;
  color: #c2c6dc;
  font-size: 18px;
}
.zonesPage .noZones .message p {
  margin: 0;
}
.zonesPage .mapContainer {
  height: 100%;
  width: 100%;
}
.zonesPage .mapContainer .card {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 1000;
  width: auto;
  padding: 20px;
}
.zonesPage .mapContainer .card .title {
  font-size: 12px;
  color: gray;
  margin-bottom: 5px;
}
.zonesPage .mapContainer .card .form {
  margin-bottom: 25px;
}
.zonesPage .mapContainer .card .actions {
  display: flex;
  justify-content: space-between;
}
.zonesPage .mapContainer .leaflet-container {
  height: 100%;
  width: 100%;
}
.ReactModalPortal .container.zoneInfo .header .name {
  font-size: 55px;
}
.ReactModalPortal .container.zoneInfo .header .surface {
  display: flex;
}
.ReactModalPortal .container.zoneInfo .header .surface .tag {
  color: gray;
}
.ReactModalPortal .container.zoneInfo .header .surface .value {
  padding-left: 10px;
  color: #2d2d3c;
}
.ReactModalPortal .container.zoneInfo .body {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-around;
}
.ReactModalPortal .container.zoneInfo .body .card {
  width: 300px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 10px;
  margin: 10px;
}
.ReactModalPortal .container.zoneInfo .body .card .icon {
  margin-right: 10px;
}
.ReactModalPortal .container.zoneInfo .body .card .data {
  flex: 1;
}
.ReactModalPortal .container.zoneInfo .body .card .data .item {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.ReactModalPortal .container.zoneInfo .body .card .data .item .tag {
  color: gray;
  font-size: 12px;
}
.ReactModalPortal .container.zoneInfo .body .noFarms {
  padding: 85px;
}
.ReactModalPortal .container.zoneInfo .body .noFarms .icon {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 25px;
}
.ReactModalPortal .container.zoneInfo .body .noFarms .icon img {
  height: 120px;
  width: 120px;
}
.ReactModalPortal .container.zoneInfo .body .noFarms .message {
  font-size: 22px;
  text-align: center;
}

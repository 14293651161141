.recoveryContainer {
  background-image: url(/assets/img/background3.jpg);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  height: 100%;
  width: 100%;
}
.recoveryContainer .overlay {
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.6);
}
.recoveryContainer .card {
  box-shadow: #000 0 0 15px;
  width: 340px;
  padding: 20px;
  transform-style: preserve-3d;
  transition: all 0.7s linear;
  margin: none;
}
.recoveryContainer .card .title {
  display: flex;
  justify-content: center;
  align-items: center;
}
.recoveryContainer .card .title img {
  width: 125px;
  height: auto;
  margin-top: -20px;
}
.recoveryContainer .card .form {
  padding: 15px 0;
  margin-top: 80px;
}
.recoveryContainer .card .form .field-wrapper:first-child {
  margin-bottom: 5px;
}
.recoveryContainer .card .form .field-wrapper:last-child {
  margin-top: 5px;
}
.recoveryContainer .card .form .field-wrapper input {
  padding-left: 35px;
  width: 280px;
  border: none;
  box-shadow: none;
  background: #e8f0fe;
}
.recoveryContainer .card .form .field-wrapper svg {
  position: absolute;
  height: 35px;
  width: 35px;
  padding: 8px;
}
.recoveryContainer .card .actions {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 80px;
}

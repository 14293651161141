 .sidenav {
	 display: flex;
	 flex-direction: column;
	 background-color: #1e2125;
	 width: 260px;
	 height: 100%;
	 position: relative;
	 -webkit-transition: all 0.25s ease;
	 transition: all 0.25s ease;
}
 .sidenav .header {
	 display: flex;
	 align-items: center;
	 position: relative;
}
 .sidenav .header .logo {
	 display: flex;
	 justify-content: center;
	 align-items: center;
	 width: 100%;
	 margin-top: 20px;
	 height: 120px;
	 opacity: 1;
	 animation-name: fadeInOpacity;
	 animation-iteration-count: 1;
	 animation-timing-function: ease-in;
	 animation-duration: 2s;
}
 .sidenav .header .logo img {
	 width: 150px;
	 height: 120px;
}
 .sidenav .header .viip {
	 font-size: 32px;
	 font-weight: 600;
	 animation: fadein 0.3s cubic-bezier(0.25, 0.8, 0.25, 1) 0s 1 normal forwards;
	 color: white;
}
 .sidenav .header .closer {
	 height: 20px;
	 width: 20px;
	 position: absolute;
	 right: 17px;
	 transform: rotate(180deg);
	 cursor: pointer;
}
 .sidenav .header .closer img {
	 height: 100%;
	 width: 100%;
}
 .sidenav .featuresList {
	 padding: 0 15px;
	 flex: 1;
	 overflow: auto;
}
 .sidenav .featuresList .subtitle {
	 color: #c2c6dc;
	 font-size: 12px;
	 display: block;
	 font-weight: 500;
	 text-transform: uppercase;
	 margin: 45px auto 15px 30px;
}
 .sidenav .featuresList .featureItem {
	 border-radius: 4px;
}
 .sidenav .featuresList .featureItem a {
	 padding: 15px;
}
 .sidenav .featuresList .featureItem.option {
	 padding-left: 30px;
}
 .sidenav .featuresList .featureItem.option .logo svg {
	 height: 10px;
	 width: 10px;
}
 .sidenav .featuresList .featureItem a {
	 text-decoration: none;
	 display: flex;
	 align-items: center;
}
 .sidenav .featuresList .featureItem.selected {
	 background: linear-gradient(118deg, #1e3c7c, rgba(34, 37, 41, 0.7));
	 box-shadow: 0 0 10px 1px rgba(34, 37, 41, 0.7);
}
 .sidenav .featuresList .featureItem:hover {
	 cursor: pointer;
}
 .sidenav .featuresList .featureItem:hover > div {
	 -webkit-transform: translateX(5px);
	 transform: translateX(5px);
}
 .sidenav .featuresList .featureItem .logo {
	 margin-right: 14px;
	 font-size: 15px;
	 color: white;
}
 .sidenav .featuresList .featureItem .logo svg {
	 height: 20px;
	 width: 20px;
}
 .sidenav .featuresList .featureItem .tag {
	 color: #c2c6dc;
	 font-size: 19px;
}
 .sidenav .poweredby {
	 height: 40px;
	 width: 100%;
	 display: flex;
	 justify-content: center;
	 align-items: center;
	 background: #1f386e;
}
 .sidenav .poweredby .tag {
	 color: gray;
	 font-size: 14px;
}
 .sidenav .poweredby .vetanco {
	 height: 100%;
	 padding: 5px;
	 margin-left: 5px;
}
 .sidenav .poweredby .vetanco img {
	 height: 100%;
	 width: auto;
}
 .sidenav .footer {
	 bottom: 0;
	 position: absolute;
	 display: flex;
	 width: 100%;
	 padding-bottom: 15px;
}
 .sidenav .footer .featureItem {
	 width: 100%;
	 display: flex;
}
 .sidenav .footer .featureItem a {
	 text-decoration: none;
	 display: flex;
	 align-items: center;
	 padding: 15px;
}
 .sidenav .footer .featureItem.selected {
	 background: linear-gradient(118deg, rgba(115, 103, 240, 1), rgba(115, 103, 240, 0.7));
	 box-shadow: 0 0 10px 1px rgba(115, 103, 240, 0.7);
}
 .sidenav .footer .featureItem:hover {
	 cursor: pointer;
}
 .sidenav .footer .featureItem:hover > div {
	 -webkit-transform: translateX(5px);
	 transform: translateX(5px);
}
 .sidenav .footer .featureItem .logo {
	 margin-right: 14px;
	 font-size: 15px;
}
 .sidenav .footer .featureItem .logo img {
	 height: 20px;
	 width: 20px;
}
 .sidenav .footer .featureItem .tag {
	 color: #c2c6dc;
	 font-size: 19px;
}
 .sidenav.closed {
	 width: 80px;
}
 .sidenav.closed .header .viip {
	 display: none;
}
 .sidenav.closed .header .logo {
	 margin-right: 0px;
}
 .sidenav.closed .header .closer {
	 display: none;
}
 .sidenav.closed .featuresList:hover {
	 cursor: pointer;
}
 .sidenav.closed .featuresList:hover > div {
	 -webkit-transform: none;
	 transform: none;
}
 .sidenav.closed .featuresList .subtitle {
	 visibility: hidden;
}
 .sidenav.closed .featuresList .featureItem .tag {
	 display: none;
}
 .sidenav.closed .footer .featureItem .tag {
	 display: none;
}
 @keyframes fadeInOpacity {
	 0% {
		 opacity: 0;
	}
	 100% {
		 opacity: 1;
	}
}
 
.panel {
  background-color: #3b3f5c;
  box-shadow: 0 15px 30px 0 rgba(0, 0, 0, .11), 0 5px 15px 0 rgba(0, 0, 0, .08);
}
.panel .panel-content .header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0;
}
.panel .panel-content .header .title {
  display: flex;
  color: #c2c6dc;
  font-size: 25px;
  line-height: 25px;
  margin-bottom: 20px;
  padding: 20px;
  padding-bottom: 0;
  justify-content: space-between;
}
.panel .panel-content .header .action {
  padding: 20px;
}
.panel .panel-content .filters {
  color: #c2c6dc;
  padding: 0 20px;
}
.panel .panel-content .filters .filter .tag {
  color: white;
  padding: 10px 0;
  font-size: 18px;
}
.panel .panel-content .filters .filter .selector span {
  color: #c2c6dc;
}
.panel .panel-content .filters .filter .selector .noneAllSelector {
  display: flex;
  padding: 5px 0;
  align-items: baseline;
}
.panel .panel-content .filters .filter .selector .noneAllSelector .separator {
  padding: 0 10px;
}
.panel .panel-content .filters .filter .selector .noneAllSelector .none, .panel .panel-content .filters .filter .selector .noneAllSelector .all {
  cursor: pointer;
}
.panel .panel-content .filters .filter .selector .ant-picker.ant-picker-range {
  margin: 5px 0;
}
.panel .panel-content .filters .filter .selector .period {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.panel .panel-content .filters .filter .selector .period input {
  width: 100px;
}
.panel .panel-content .filters .filter .selector .period .separator {
  padding: 0 5px;
}
.panel .panel-content .filters .filter.vacunacion {
  margin-top: 50px;
}
.ant-select-dropdown, .ant-tooltip, .ant-picker-dropdown {
  z-index: 16000;
}
.ant-tooltip-inner {
  background-color: #1a1f33;
}
.ant-picker input {
  box-shadow: none;
}
.ant-select-multiple .ant-select-selector {
  background-color: #404673;
}
.panel .panel-content .filters .filter .selector .ant-select-selector span {
  color: #212529;
}
.panel .ant-checkbox-checked .ant-checkbox-inner {
  background-color: #404673;
  border-color: #d9d9d9;
}

.section.coccidiosis .card.coccidiosisSpeedometer {
  width: 45%;
}
.section.coccidiosis .card.coccidiosisSpeedometer .head {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  padding: 1.5rem 1.5rem 0;
}
.section.coccidiosis .card.coccidiosisSpeedometer .head .title {
  padding: 0;
}
.section.coccidiosis .card.coccidiosisSpeedometer .head .info {
  font-size: 18px;
}
.section.coccidiosis .card.coccidiosisSpeedometer .graph {
  height: auto;
  width: auto;
  max-width: 440px;
  overflow: hidden;
  padding: 20px 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.section.coccidiosis .card.macroscopia {
  width: 25%;
}
.section.coccidiosis .card.macroscopia .head {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  padding: 1.5rem 1.5rem 0;
}
.section.coccidiosis .card.macroscopia .head .title {
  padding: 0;
}
.section.coccidiosis .card.macroscopia .head .info {
  font-size: 18px;
}
.section.coccidiosis .card.macroscopia .head .info .tooltip {
  right: 20px;
  left: auto;
}
.section.coccidiosis .card.microscopia {
  width: 25%;
}
.section.coccidiosis .card.microscopia .head {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  padding: 1.5rem 1.5rem 0;
}
.section.coccidiosis .card.microscopia .head .title {
  padding: 0;
}
.section.coccidiosis .card.microscopia .head .info {
  font-size: 18px;
}
.section.coccidiosis .card.microscopia .head .info .tooltip {
  right: 20px;
  left: auto;
}
.section.coccidiosis .card .compositionList {
  margin-top: 20px;
}
.section.coccidiosis .card .compositionList .item .tag {
  font-size: 16px;
  color: #3b3f5c;
  padding-left: 20px;
  font-style: italic;
}
.section.coccidiosis .card .compositionList .item .scoreLine {
  height: 35px;
  margin: 10px 20px;
  margin-top: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.section.coccidiosis .card .compositionList .item .scoreLine .dots {
  display: flex;
}
.section.coccidiosis .card .compositionList .item .scoreLine .dots .dot {
  height: 15px;
  width: 15px;
  border: solid 1px #888ea8;
  background: white;
  margin-right: 18px;
  border-radius: 50%;
}
.section.coccidiosis .card .compositionList .item .scoreLine .dots .dot.selected {
  background: #dc3545;
}
.section.coccidiosis .card .compositionList .item .scoreLine .dots .dot:first-child {
  margin-left: 10px;
}
.section.coccidiosis .card .compositionList .item .scoreLine .score {
  color: #2d2d3c;
}
.section.coccidiosis .card .compositionList .item .scoreLine .score .value {
  font-size: 30px;
}
.section.coccidiosis .card .compositionList .item .scoreLine .score .total {
  font-size: 14px;
}

.section.average .rowContainer {
  justify-content: space-between;
}
.section.average .rowContainer .card {
  padding: 20px;
  width: 23%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.section.average .rowContainer .card .data {
  flex: 2;
}
.section.average .rowContainer .card .data .amount {
  color: #28283c;
  margin-bottom: 0.25rem !important;
  font-weight: 700 !important;
  font-size: 24.36px;
}
.section.average .rowContainer .card .data .tag {
  color: #2d2d3c;
  font-size: 14px;
}
.section.average .rowContainer .card .icon {
  flex: 1;
}
.section.average .rowContainer .card .icon .container {
  height: 45px;
  width: 45px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.section.average .rowContainer .card:first-child .icon .container {
  color: #28c76f;
  background-color: rgba(40, 199, 111, 0.15);
}
.section.average .rowContainer .card:nth-child(2) .icon .container {
  color: #7367f0;
  background-color: rgba(115, 103, 240, 0.15);
}
.section.average .rowContainer .card:nth-child(3) .icon .container {
  color: #ea5455;
  background-color: rgba(234, 84, 85, 0.15);
}
.section.average .rowContainer .card:last-child .icon .container {
  color: #ff9f43;
  background-color: rgba(255, 159, 67, 0.15);
}
.section.average .rowContainer .card.photos, .section.average .rowContainer .card.observations {
  cursor: pointer;
}
.section.average .rowContainer .card.farms:hover .popup {
  display: block;
}
.section.average .rowContainer .card.farms .popup {
  display: none;
  position: absolute;
  background: white;
  border-radius: 0.5rem;
  box-shadow: 0 4px 25px 0 rgba(0, 0, 0, 0.1);
  -webkit-transition: all 0.3s ease-in-out;
  margin-bottom: 2.2rem !important;
  width: 210px;
  padding: 10px;
  bottom: 30px;
  right: -170px;
  z-index: 1;
}
.section.average .rowContainer .card.farms .popup .title {
  font-size: 24px;
}
.section.average .rowContainer .card.farms .popup hr {
  margin: 5px 0;
  margin-right: 155px;
}
.section.average .rowContainer .card.farms .popup .data {
  flex: 1;
}
.section.average .rowContainer .card.farms .popup .data .item {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.section.average .rowContainer .card.farms .popup .data .item .tag {
  color: gray;
  font-size: 12px;
}

.modalContainer {
  position: relative;
}
.modalContainer .report {
  padding: 20px;
}
.modalContainer .report .title {
  font-size: 36px;
  text-align: center;
  font-weight: 600;
}
.modalContainer .report .company {
  text-align: center;
  font-weight: 600;
  margin: 20px 0;
}
.modalContainer .report .farm {
  font-size: 22px;
  color: #2f5496;
  text-transform: uppercase;
  font-weight: 600;
  margin: 60px 0;
}
.modalContainer .report .agrupacion .subtitle {
  margin-top: 20px;
}
.modalContainer .report .agrupacion .items ul li {
  display: flex;
  justify-content: space-between;
  list-style: none;
  padding-bottom: 20px;
}
.modalContainer .report .agrupacion .items ul li .tag {
  padding-right: 80px;
  font-weight: 600;
}
.modalContainer .report .subtitle {
  font-size: 18px;
  color: #2f5496;
  margin-bottom: 10px;
}
.modalContainer .report .item .tag {
  display: inline-block;
  font-weight: 600;
}
.modalContainer .report .item .value {
  display: inline-block;
  padding-left: 10px;
}
.modalContainer .report .vacuna {
  margin: 40px 0;
}
.modalContainer .report .summery-message {
  margin: 20px 0;
}
.modalContainer .report .description-result {
  color: #0070c0;
}
.modalContainer .report .description.space-40 {
  padding: 40px 0;
}
.modalContainer .report .closure-message {
  margin-top: 40px;
  font-size: 10px;
  font-weight: 600;
  font-style: italic;
  text-align: center;
}
.modalContainer .report .sections .section {
  margin-bottom: 10px;
}
.modalContainer .report .sections .section .name {
  text-transform: uppercase;
  font-weight: 600;
  margin-bottom: 15px;
}
.modalContainer .report .sections .section .rowContainer {
  padding: 20px 0;
}
.modalContainer .report .sections .section .rowContainer > div {
  display: inline-block;
  width: 45%;
  vertical-align: middle;
}
.modalContainer .report .sections .section.quality .info > div {
  display: inline-block;
  width: 45%;
  vertical-align: middle;
}
.modalContainer .report .sections .section.quality .info .graph {
  position: relative;
  padding: 0 20px;
}
.modalContainer .report .sections .section.quality .info .graph .percentage {
  position: absolute;
  color: #28283c;
  width: 100%;
  top: 0;
  left: 0;
  text-align: center;
  padding-top: 60px;
}
.modalContainer .report .sections .section.quality .info .graph .percentage .value {
  font-size: 88px;
}
.modalContainer .report .sections .section.quality .info .graph .percentage .unit {
  font-size: 30px;
}
.modalContainer .report .sections .section.digestive .graph.digestive {
  margin: auto;
}
.modalContainer .report .sections .section.digestive .graph.digestive > div {
  display: inline-block;
  width: 45%;
  vertical-align: middle;
}
.modalContainer .report .sections .section.digestive .graph.digestive .graph {
  position: relative;
  width: 200px;
}
.modalContainer .report .sections .section.digestive .graph.digestive .percentage {
  display: inline-block;
  color: #282832;
  position: absolute;
  top: 33%;
}
.modalContainer .report .sections .section.digestive .graph.digestive .percentage .value {
  font-size: 45px;
  padding-left: 30px;
}
.modalContainer .report .sections .section.digestive .graph.digestive .percentage .unit {
  font-size: 18px;
}
.modalContainer .report .sections .section.digestive .graph.digestive .progressBar {
  display: inline-block;
  padding: 2px;
  overflow: hidden;
  background-color: #f5f5f5;
  border-radius: 0.25rem;
  height: 225px;
  width: 20px;
}
.modalContainer .report .sections .section.digestive .graph.digestive .progressBar .progressValue {
  border-radius: 0.25rem;
  width: 100%;
  background: #f04942;
  position: relative;
}
.modalContainer .report .sections .section.digestive .graph.digestive .progressBar .progressValue:before {
  content: '';
  height: 6px;
  width: 6px;
  background-color: #212529;
  position: absolute;
  right: 5px;
  border-radius: 50%;
  top: 5px;
}
.modalContainer .report .sections .section.digestive .graph.digestive .tractos {
  display: inline-block;
  padding: 20px 40px 20px 0;
  width: 200px;
  margin-left: 40px;
}
.modalContainer .report .sections .section.digestive .graph.digestive .tractos .tracto {
  padding: 10px 0;
  position: relative;
}
.modalContainer .report .sections .section.digestive .graph.digestive .tractos .tracto .value {
  font-size: 16px;
  font-weight: 600;
}
.modalContainer .report .sections .section.digestive .graph.digestive .tractos .tracto .info {
  display: none;
  position: absolute;
  top: 10px;
  right: 0;
}
.modalContainer .report .sections .section.digestive .graph.digestive .tractos .liver {
  padding: 10px 0;
}
.modalContainer .report .sections .section.digestive .graph.digestive .tractos .liver > div {
  display: inline-block;
}
.modalContainer .report .sections .section.digestive .graph.digestive .tractos .liver .iconState {
  height: 15px;
  width: 15px;
  min-width: 15px;
  border-radius: 50%;
  margin-right: 10px;
}
.modalContainer .report .sections .section.digestive .graph.digestive .tractos .liver .tag {
  font-size: 12px;
}
.modalContainer .report .sections .section.digestive .graph.digestive .tractos .liver .info {
  display: none;
}
.modalContainer .report .sections .section.digestive .graph.digestive .tractos hr {
  margin: 0 auto;
  width: 30px;
}
.modalContainer .report .sections .section.digestive .graph.distribution .graph {
  padding: 0;
  width: auto;
  width: 100%;
  min-height: 300px;
  height: 300px;
}
.modalContainer .report .sections .section.digestive .graph.distribution .composition .references {
  display: none;
}
.modalContainer .report .sections .section.digestive .graph.distribution .composition .references .reference {
  padding: 5px 0;
}
.modalContainer .report .sections .section.digestive .graph.distribution .composition .references .reference > div {
  display: inline-block;
}
.modalContainer .report .sections .section.digestive .graph.distribution .composition .references .reference .color {
  height: 20px;
  width: 20px;
  border-radius: 50%;
  border: solid 1px white;
  margin-right: 15px;
}
.modalContainer .report .sections .section.digestive .graph.distribution .composition .references .reference .tag {
  color: #282832;
}
.modalContainer .report .sections .section.cocci .compositionList {
  margin-top: 20px;
  min-width: 370px;
}
.modalContainer .report .sections .section.cocci .compositionList .item {
  padding: 7px 0;
}
.modalContainer .report .sections .section.cocci .compositionList .item .tag {
  font-size: 16px;
  color: #3b3f5c;
  padding-left: 20px;
  font-style: italic;
  width: 125px;
}
.modalContainer .report .sections .section.cocci .compositionList .item .scoreLine {
  height: 35px;
  margin: 10px 20px;
  margin-top: 0;
}
.modalContainer .report .sections .section.cocci .compositionList .item .scoreLine > div {
  display: inline-block;
}
.modalContainer .report .sections .section.cocci .compositionList .item .scoreLine .dots > div {
  display: inline-block;
}
.modalContainer .report .sections .section.cocci .compositionList .item .scoreLine .dots .dot {
  height: 15px;
  width: 15px;
  border: solid 1px #888ea8;
  background: white;
  margin-right: 18px;
  border-radius: 50%;
}
.modalContainer .report .sections .section.cocci .compositionList .item .scoreLine .dots .dot.selected {
  background: #dc3545;
}
.modalContainer .report .sections .section.cocci .compositionList .item .scoreLine .dots .dot:first-child {
  margin-left: 10px;
}
.modalContainer .report .sections .section.cocci .compositionList .item .scoreLine .score {
  color: #2d2d3c;
}
.modalContainer .report .sections .section.cocci .compositionList .item .scoreLine .score .value {
  font-size: 30px;
}
.modalContainer .report .sections .section.cocci .compositionList .item .scoreLine .score .total {
  font-size: 14px;
}
.modalContainer .report .sections .section .risk .graph {
  width: 450px;
  margin: 20px auto;
}
.modalContainer .report .sections .section.observations img {
  max-width: 90%;
  max-height: 800px;
  margin: auto;
  display: block;
}
.modalContainer .download {
  position: sticky;
  top: 10px;
  float: right;
  display: flex;
  z-index: 1;
  justify-content: center;
  align-items: center;
  background-color: #4caf50;
  color: white;
  font-size: 18px;
  height: 40px;
  width: 180px;
  box-shadow: 0 4px 16px 0 black;
  cursor: pointer;
}
.modalContainer .download .tag {
  padding-left: 10px;
}
.ReactModal__Overlay.ReactModal__Overlay--after-open {
  z-index: 2;
}

.dashboardsPage {
  padding: 40px;
}
.dashboardsPage .header {
  display: flex;
  color: #2d2d3c;
  font-size: 25px;
  line-height: 25px;
  margin-bottom: 20px;
  justify-content: space-between;
}
.dashboardsPage .header .left {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.dashboardsPage .header .left .separator {
  line-height: 25px;
  padding: 0 10px;
}
.dashboardsPage .header .left .icon {
  height: 25px;
  width: 25px;
}
.dashboardsPage .header .left .icon img {
  height: 100%;
  width: 100%;
}
.dashboardsPage .header .left .type {
  padding-left: 15px;
}
.dashboardsPage .header .left .switch {
  margin-left: 20px;
}
.dashboardsPage .header .left .switch button {
  font-size: 14px;
}
.dashboardsPage .header .left .formOpener {
  margin-left: 10px;
}
.dashboardsPage .header .left .formOpener button {
  font-size: 12px;
}
.dashboardsPage .header .left .btn-group button {
  margin: 0 3px;
}
.dashboardsPage .header .right .filterOpener {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.dashboardsPage .header .right .filterOpener .tag {
  font-size: 16px;
  padding-left: 10px;
}
.dashboardsPage .sections {
  scroll-behavior: smooth;
}
.dashboardsPage .section > .title {
  display: flex;
  color: #2d2d3c;
  font-size: 22px;
  line-height: 22px;
  margin-bottom: 20px;
  justify-content: flex-start;
}
.dashboardsPage .card {
  border-radius: 0.5rem;
  box-shadow: 0 4px 25px 0 rgba(0, 0, 0, .1);
  -webkit-transition: all 0.3s ease-in-out;
  margin-bottom: 2.2rem !important;
  width: 45%;
}
.dashboardsPage .card.full {
  width: 100%;
}
.dashboardsPage .card .title {
  padding: 1.5rem 1.5rem 0;
  font-size: 18.48px;
}
.dashboardsPage .card .graph {
  height: 300px;
  width: 100%;
  padding: 20px;
}
.dashboardsPage .card .graph.radar .recharts-text.recharts-polar-angle-axis-tick-value {
  fill: white;
}
.dashboardsPage .card .graph.historicalForms .recharts-legend-item-text {
  color: white;
}
.dashboardsPage .card .message {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
}
.dashboardsPage .card .message .icon {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
  width: 100px;
  margin-bottom: 20px;
}
.dashboardsPage .card .message .icon img {
  height: 100%;
  width: 100%;
}
.dashboardsPage .card .message .text {
  color: white;
  text-align: center;
  font-size: 18px;
}
.dashboardsPage .card .success {
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  flex: 1;
}
.dashboardsPage .card .success .icon {
  margin: 20px;
}
.dashboardsPage .card .success .icon img, .dashboardsPage .card .success .icon svg {
  height: 80px;
  width: 80px;
}
.dashboardsPage .card .success .message {
  height: auto;
}
.dashboardsPage .rowContainer {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.dashboardsPage .noRecords {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.dashboardsPage .noRecords .logo {
  height: 250px;
  margin-top: 80px;
}
.dashboardsPage .noRecords .logo img {
  height: 100%;
  width: auto;
}
.dashboardsPage .noRecords .title {
  font-size: 32px;
  padding: 40px;
  padding-bottom: 0;
}
.dashboardsPage .noRecords .message {
  text-align: center;
  padding: 20px;
  color: #c2c6dc;
  font-size: 18px;
}
.dashboardsPage .noRecords .message p {
  margin: 0;
}
.dashboardsPage .removeForm {
  display: flex;
  justify-content: center;
  align-items: center;
}
.dashboardsPage .removeForm .button {
  display: flex;
  padding: 10px 20px;
  background-color: white;
  color: #2d2d3c;
  border-radius: 0.5rem;
  box-shadow: 0 4px 25px 0 rgba(0, 0, 0, 0.1);
  -webkit-transition: all 0.3s ease-in-out;
  color: red;
  cursor: pointer;
}
.dashboardsPage .removeForm .button:hover {
  color: white;
  background: red;
}
.dashboardsPage .removeForm .button .logo {
  margin-right: 5px;
}

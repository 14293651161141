.dashboardsPage .card.digestiveSystemPercentage {
  width: 35%;
  min-width: 190px;
}
.dashboardsPage .card.digestiveSystemPercentage .head {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  padding: 1.5rem 1.5rem 0;
}
.dashboardsPage .card.digestiveSystemPercentage .head .title {
  padding: 0;
}
.dashboardsPage .card.digestiveSystemPercentage .head .info {
  font-size: 18px;
}
.dashboardsPage .card.digestiveSystemPercentage .data {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 1;
}
.dashboardsPage .card.digestiveSystemPercentage .data .rowContainer {
  flex: 1;
  padding: 20px 30px;
  justify-content: flex-start;
  flex-wrap: nowrap;
}
.dashboardsPage .card.digestiveSystemPercentage .data .rowContainer .graph {
  flex: 1;
  display: flex;
  justify-content: flex-start;
  flex-wrap: nowrap;
}
.dashboardsPage .card.digestiveSystemPercentage .data .rowContainer .graph .percentage {
  color: #282832;
  display: flex;
  align-items: baseline;
  justify-content: center;
  padding-top: 70px;
}
.dashboardsPage .card.digestiveSystemPercentage .data .rowContainer .graph .percentage .value {
  font-size: 45px;
  padding-left: 30px;
}
.dashboardsPage .card.digestiveSystemPercentage .data .rowContainer .graph .percentage .unit {
  font-size: 18px;
}
.dashboardsPage .card.digestiveSystemPercentage .data .rowContainer .graph .progressBar {
  display: flex;
  align-items: flex-end;
  padding: 2px;
  overflow: hidden;
  background-color: #f5f5f5;
  border-radius: 0.25rem;
  height: 225px;
  width: 20px;
}
.dashboardsPage .card.digestiveSystemPercentage .data .rowContainer .graph .progressBar .progressValue {
  border-radius: 0.25rem;
  width: 100%;
  background: #f04942;
  position: relative;
}
.dashboardsPage .card.digestiveSystemPercentage .data .rowContainer .graph .progressBar .progressValue:before {
  content: '';
  height: 6px;
  width: 6px;
  background-color: #212529;
  position: absolute;
  right: 5px;
  border-radius: 50%;
  top: 5px;
}
.dashboardsPage .card.digestiveSystemPercentage .data .tractos {
  flex: 1;
  padding: 20px 40px 20px 0;
}
.dashboardsPage .card.digestiveSystemPercentage .data .tractos .tracto {
  padding: 10px 0;
  position: relative;
}
.dashboardsPage .card.digestiveSystemPercentage .data .tractos .tracto .value {
  font-size: 16px;
  font-weight: 600;
}
.dashboardsPage .card.digestiveSystemPercentage .data .tractos .tracto .info {
  position: absolute;
  top: 10px;
  right: 0;
}
.dashboardsPage .card.digestiveSystemPercentage .data .tractos .liver {
  display: flex;
  padding: 10px 0;
  align-items: center;
  justify-content: flex-start;
}
.dashboardsPage .card.digestiveSystemPercentage .data .tractos .liver .iconState {
  height: 15px;
  width: 15px;
  min-width: 15px;
  border-radius: 50%;
  margin-right: 10px;
}
.dashboardsPage .card.digestiveSystemPercentage .data .tractos .liver .tag {
  font-size: 12px;
}
.dashboardsPage .card.digestiveSystemPercentage .data .tractos hr {
  margin: 0 auto;
  width: 30px;
}
.dashboardsPage .card.distribution {
  width: 62.5%;
  min-width: 450px;
}
.dashboardsPage .card.distribution .head {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  padding: 1.5rem 1.5rem 0;
}
.dashboardsPage .card.distribution .head .title {
  padding: 0;
}
.dashboardsPage .card.distribution .head .info {
  font-size: 18px;
}
.dashboardsPage .card.distribution .head .info .tooltip {
  right: 20px;
  left: auto;
}
.dashboardsPage .card.distribution .rowContainer {
  min-width: 440px;
  padding: 20px;
}
.dashboardsPage .card.distribution .rowContainer .composition {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.dashboardsPage .card.distribution .rowContainer .composition .graph {
  padding: 0;
  width: auto;
  min-width: 450px;
}
.dashboardsPage .card.distribution .rowContainer .composition .references {
  min-width: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding-left: 30px;
}
.dashboardsPage .card.distribution .rowContainer .composition .references .reference {
  display: flex;
  align-items: center;
  padding: 5px 0;
}
.dashboardsPage .card.distribution .rowContainer .composition .references .reference .color {
  height: 20px;
  width: 20px;
  border-radius: 50%;
  border: solid 1px white;
  margin-right: 15px;
}
.dashboardsPage .card.distribution .rowContainer .composition .references .reference .tag {
  color: #282832;
}
